<template>
  <structure-auth>
    <figure>
      <hs-logo height="25" />
    </figure>
    <hs-loading v-if="isLoading" />
    <template v-else>
      <h3 class="text-center py-3 my-3" v-if="schoolError">{{ $t('sparkfunnels.invite.error.school') }}</h3>
      <h3 class="text-center py-3 my-3" v-else-if="inviteError">{{ $t('sparkfunnels.invite.error.invite') }}</h3>
      <FormInvite v-else :schoolName="selectedSchool.name" @register="register" />
    </template>
  </structure-auth>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import StructureAuth from '@/auth/components/Auth';
import { hsLoading, hsLogo } from '@/components';
import FormInvite from './components/Form';

export default {
  components: {
    FormInvite,
    hsLoading,
    hsLogo,
    StructureAuth,
  },
  computed: {
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
    }),
  },
  props: {
    subDomain: {
      required: true,
      type: String,
    },
    token: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      inviteError: false,
      isLoading: false,
      schoolError: false,
    };
  },
  methods: {
    ...mapActions('auth', ['invite']),
    ...mapActions('school', ['getParams']),
    register(form) {
      this.isLoading = true;
      const payload = {
        invitation_token: this.token,
        realm_id: this.selectedSchool.id,
        realm_type: 'School',
        password: form.password,
        password_confirmation: form.confirmPassword,
      };
      this.invite(payload)
        .then(() => this.$router.push({ name: 'MenuHeaderLayout' }))
        .catch(() => (this.inviteError = true))
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.isLoading = true;
    this.getParams({ subDomain: this.subDomain })
      .catch(() => (this.schoolError = true))
      .finally(() => (this.isLoading = false));
  },
};
</script>
