<template>
  <div>
    <hs-form @submit="register">
      <h5 v-show="schoolName" v-html="$t('sparkfunnels.invite.form.title', { schoolName })"></h5>

      <b-form-group>
        <label>{{ $t('sparkfunnels.invite.form.fields.password.name') }}</label>
        <hs-input
          id="invite_newPassword"
          type="password"
          @blur="$v.form.password.$touch()"
          v-model="form.password"
          :state="!$v.form.password.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.form.password.$error">
            <hs-form-invalid-feedback :state="false">
              <span v-if="!$v.form.password.required">
                {{ $t('sparkfunnels.invite.form.validation.required') }}
              </span>
              <span v-if="!$v.form.password.minLength">
                {{ $t('sparkfunnels.invite.form.validation.password.minLength', { minLength: 6 }) }}
              </span>
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </b-form-group>

      <b-form-group>
        <label>{{ $t('sparkfunnels.invite.form.fields.password_confirm.name') }}</label>
        <hs-input
          id="invite_confirmPassword"
          type="password"
          @blur="$v.form.confirmPassword.$touch()"
          v-model="form.confirmPassword"
          :state="!$v.form.confirmPassword.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.form.confirmPassword.$error">
            <hs-form-invalid-feedback :state="false" v-if="!$v.form.confirmPassword.sameAsPassword">
              {{ $t('sparkfunnels.invite.form.validation.password.sameAs') }}
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </b-form-group>

      <hs-button block variant="primary" type="submit" :disabled="$v.form.$invalid">
        {{ $t('sparkfunnels.invite.form.buttons.accept') }}
      </hs-button>
    </hs-form>
  </div>
</template>

<script>
import { hsForm } from '@/components';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';

export default {
  props: {
    schoolName: {
      required: true,
      type: String,
    },
  },
  components: {
    hsForm,
  },
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null,
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  methods: {
    register() {
      this.$emit('register', this.form);
    },
  },
};
</script>

<style></style>
